<template>
  <div class="jumbotron">
    <h1 class="display-4">Logowanie</h1>

    <b-card header="Zaloguj się" style="max-width: 600px;margin: 0 auto;">
      <b-form @submit="onSubmit">
        <b-form-group
            id="input-group-1"
            label="Identyfikator urządzenia:"
            label-for="input-1"
            description="Tutaj wpisz identyfikator, który był wraz z licznikiem"
        >
          <b-form-input
              id="input-1"
              v-model="form.login"
              type="text"
              placeholder="Wprowadź identyfikator"
              required
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label="Hasło" label-for="input-2"
                      description="Wprowadź hasło do licznika. Domyślne hasło do licznika to: defpass"
        >
          <b-form-input
              id="input-2"
              v-model="form.password"
              placeholder="Wprowadź hasło"
              required
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" variant="primary">Zaloguj</b-button>
      </b-form>
    </b-card>
  </div>
</template>

<script>

export default {
  name: 'Login',
  data() {
    return {
      form: {
        login: '',
        password: '',
      },
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.$http.post(this.$api.LOGIN, this.form).then((response) => {
        localStorage.setItem('token', response.data.access_token);
        this.$store.commit('loginSuccess', response.data.access_token);
        this.$notify({
          group: 'default',
          title: 'Zalogowano pomyślnie!',
          type: 'success',
          text: 'Miłego korzystania z licznika!'
        });
        this.$router.push('/settings');


      }).catch(() => {
        this.$notify({
          group: 'default',
          title: 'Podane dane logowania są błędne',
          type: 'error',
          text: 'Sprawdź dane logowania i spróbuj ponownie.'
        });
      });
    },
  }
}
</script>
